<template>
  <div class="page">
    <el-card>
      <template slot="header">
        <span class="title">{{ this.$route.params && this.$route.params.id ? '编辑' : '新增' }}礼包</span>
        <div class="button-back">
          <el-button @click="() => {
            $router.go(-1);
          }
            " size="small" class="back_btn">
            <img src="@/assets/back.png" alt="" />
          </el-button>
        </div>
      </template>

      <div v-loading="loading">
        <div class="content-goods-publish">
          <el-form :model="baseInfoForm" status-icon :rules="baseInfoFormRule" label-position="right" ref="baseInfoForm"
            label-width="120px" class="demo-ruleForm">
            <div class="base-info-item">
              <div>
                <el-form-item label="礼包名称" prop="gift_name" class="goods-name-width">
                  <el-input v-model="baseInfoForm.gift_name" @change="() => {
                    baseInfoForm.gift_name = baseInfoForm.gift_name.trim();
                  }
                    " :maxlength="32" :minlength="1" placeholder="1-32个字符"></el-input>
                </el-form-item>

                <el-form-item label="前端礼包名称" prop="web_gift_name" class="goods-name-width">
                  <el-input v-model="baseInfoForm.web_gift_name" @change="() => {
                    baseInfoForm.web_gift_name =
                      baseInfoForm.web_gift_name.trim();
                  }
                    " :maxlength="32" :minlength="1" placeholder="1-32个字符"></el-input>
                </el-form-item>

                <!-- <el-form-item label="所属公司" prop="company" class="goods-name-width">
                <el-input v-model="baseInfoForm.company"></el-input>
              </el-form-item> -->

                <el-form-item label="礼包图片" prop="goods_gallery" style="width: 90%; text-align: left">
                  <el-upload class="avatar-uploader goods-images" :class="baseInfoForm.goods_gallery_list.length >= 5 &&
                    'no-plus-icon'
                    " :action="`${MixinUploadApi}?scene=goods`" list-type="picture-card" multiple :limit="5"
                    :on-exceed="handleExceed" :file-list="goodsGalleryList" :on-preview="handlePictureCardPreview"
                    :before-upload="beforeAvatarUpload" :on-remove="handleRemove" :on-success="handleSuccess">
                    <!-- <span style="display: none;"  v-model="baseInfoForm.goods_gallery"></span> -->
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                    <div slot="tip" class="el-upload__tip">
                      建议图片尺寸比例为4:3，大小不超5MB，只限png/jpg格式，最多上传5张图片。
                    </div>
                  </el-upload>
                  <el-dialog :visible.sync="dialogImage">
                    <img width="100%" :src="dialogImageUrl" alt />
                  </el-dialog>
                </el-form-item>

                <el-form-item label="选择商品" prop="goods_data" class="goods-name-width">
                  <el-button type="primary" size="small" @click="showDialog = true">选择商品</el-button>
                </el-form-item>

                <!-- 商品选择区 start -->
                <el-form-item style="width: 100%" class="goods-select">
                  <en-table-layout :tableData="goodsData" style="width: 100%" ref="tableWrap">
                    <template slot="table-columns">
                      <el-table-column label="商品图片" width="60" class-name="goods-cover-wrapper" fixed="left">
                        <template slot-scope="{ row }">
                          <el-popover placement="right" trigger="hover">
                            <img :src="row.thumbnail" alt style="width: 300px" />
                            <img :src="row.thumbnail" class="goods-cover" alt slot="reference" />
                          </el-popover>
                        </template>
                      </el-table-column>
                      <el-table-column label="前端展示图片" width="70" class-name="goods-cover-wrapper" fixed="left">
                        <template slot-scope="{ row, $index }">
                          <div style="position: relative">
                            <div class="changBtn">
                              <el-upload class="upload-demo" :action="`${MixinUploadApi}?scene=goods`"
                                :on-success="thumbnailSuccess" :show-file-list="false">
                                <el-button @click="setIndex($index, 1)" style="
                                    padding: 0;
                                    color: #fff;
                                    font-size: 12px;
                                  " type="text">更换图片</el-button>
                              </el-upload>
                            </div>
                            <el-popover placement="right" trigger="hover">
                              <img :src="row.web_thumbnail
                                ? row.web_thumbnail
                                : row.thumbnail
                                " alt style="width: 300px" />
                              <img :src="row.web_thumbnail
                                ? row.web_thumbnail
                                : row.thumbnail
                                " class="goods-cover" alt slot="reference" />
                            </el-popover>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column label="商品名称" :show-overflow-tooltip="true" width="200">
                        <template slot-scope="scope">
                          <span v-html="scope.row.goods_name"></span>
                        </template>
                      </el-table-column>
                      <el-table-column label="前端名称" :show-overflow-tooltip="true" width="200">
                        <template slot-scope="scope">
                          <el-input v-model="scope.row.goods_alias"></el-input>
                        </template>
                      </el-table-column>
                      <el-table-column label="商品编号" :show-overflow-tooltip="true" width="200">
                        <template slot-scope="{ row }">
                          <span v-html="row.self_operated !== 1 && row.goods_source === 1
                            ? row.ext_goods_id
                            : row.sn
                            "></span>
                        </template>
                      </el-table-column>
                      <el-table-column label="礼包内数量" width="100">
                        <template slot-scope="scope">
                          <el-input style="text-align: center" v-model="scope.row.num"
                            oninput="value=value.replace(/[^0-9]/g,'')" @input="getPrice"></el-input>
                        </template>
                      </el-table-column>
                      <el-table-column label="可换商品数" width="100" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                          <span v-html="scope.row.exchange_goods_num" v-if="scope.row.exchange_goods_num"></span>
                          <div v-else style="text-align: center">0</div>
                        </template>
                      </el-table-column>
                      <el-table-column label="商品来源" width="100">
                        <!-- 商品来源，0普通商品，1京东商品，2苏宁商品 -->
                        <template slot-scope="scope">
                          <template v-if="scope.row.self_operated">
                            <template v-if="scope.row.self_operated === 0">
                              <span v-if="scope.row.goods_source === 0">
                                精选优品
                              </span>
                              <span v-else>
                                京东商品
                              </span>
                            </template>
                            <template v-else>
                              自有商品
                            </template>
                          </template>
                          <template v-else>
                            <span v-if="scope.row.goods_source === 0">平台商品</span>
                            <span v-if="scope.row.goods_source === 9">自有商品</span>
                            <span v-if="scope.row.goods_source === 1">京东商品</span>
                          </template>
                        </template>
                      </el-table-column>
                      <el-table-column width="200" label="所属供应商" v-if="$store.getters.isSupplierName">
                        <template slot-scope="scope">{{
                          scope.row.supplier_name || scope.row.seller_name
                        }}</template>
                      </el-table-column>
                      <el-table-column label="会员价" width="100" prop="price" v-if="MixinIsFormEnterprise">
                        <template slot-scope="scope">{{
                          scope.row.price | unitPrice("￥")
                        }}</template>
                      </el-table-column>

                      <el-table-column label="市场价" width="100" prop="market">
                        <template slot-scope="scope">{{
                          scope.row.mktprice | unitPrice("￥")
                        }}</template>
                      </el-table-column>

                      <el-table-column label="分销价" width="100" prop="enterprisePrice">
                        <template slot-scope="scope">{{
                          scope.row.enterprise_revise_price | unitPrice("￥")
                        }}</template>
                      </el-table-column>
                      <!-- v-if="MixinIsFormEnterprise" -->
                      <el-table-column label="毛利率" width="100" prop="profit">
                        <template slot-scope="scope">{{ scope.row.enterprise_profit }}%</template>
                      </el-table-column>

                      <el-table-column label="销售价" width="100" prop="revisePrice">
                        <template slot-scope="scope">{{
                          scope.row.revise_price | unitPrice("￥")
                        }}</template>
                      </el-table-column>
                      <!-- v-if="MixinIsFormEnterprise" -->
                      <el-table-column label="利润率" width="100" prop="shopProfit">
                        <template slot-scope="scope">{{ scope.row.shop_profit }}%</template>
                      </el-table-column>

                      <el-table-column label="库存" width="100">
                        <!-- 商品来源，0普通商品，1京东商品，2苏宁商品 -->
                        <template slot-scope="scope">
                          <template v-if="scope.row.goods_source === 1">
                            <template v-if="scope.row.enable_quantity > 0">有货</template>
                            <template v-else>无货</template>
                          </template>
                          <template v-else>{{ scope.row.enable_quantity || 0 }}件</template>
                        </template>
                      </el-table-column>
                      <el-table-column label="状态" width="100">
                        <template slot-scope="scope">
                          <span v-if="scope.row.shop_goods_status === 0 ||
                            scope.row.disabled === 0
                            ">
                            已下架
                            <span style="color: red">（已失效）</span>
                          </span>
                          <span v-else>已上架</span>
                        </template>
                      </el-table-column>
                      <el-table-column label="操作" fixed="right" width="320">
                        <template slot-scope="scope">
                          <el-button v-if="manual_exchange_goods" :disabled="scope.row.not_put" size="mini" type="primary"
                            @click="changeFun(scope.row)">替换商品</el-button>
                          <el-button size="mini" type="danger"
                            @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                          <el-button v-if="scope.row.seq !== goodsData[0].seq" type="warning"
                            :disabled="scope.row.not_put" icon="el-icon-top" circle size="mini" @click="
                              movement(scope.row, 'up', 'moveComboGoodsTh')
                              "></el-button>
                          <el-button v-if="scope.row.seq !==
                            goodsData[goodsData.length - 1].seq
                            " type="warning" :disabled="scope.row.not_put" icon="el-icon-bottom" circle size="mini"
                            @click="
                              movement(scope.row, 'down', 'moveComboGoodsTh')
                              "></el-button>
                          <div v-if="scope.row.not_put" style="color: red; font-size: 12px">
                            (保存挑选商品后再选择替换商品)
                          </div>
                        </template>
                      </el-table-column>
                    </template>
                  </en-table-layout>
                </el-form-item>
                <el-form-item label="合计" style="width: 100%">
                  <el-table :data="totalData" style="width: 100%">
                    <el-table-column prop="combo_price" label="套餐价">
                      <template>
                        <el-input placeholder="请输入套餐价" v-model="baseInfoForm.combo_price"
                          oninput="value = (value.match(/\d+(.\d{0,2})?/) || [''])[0]"
                          @input="getPrice(1, 'change_combo_price')"></el-input>
                      </template>
                    </el-table-column>
                    <el-table-column prop="market_sum_price" label="市场总价">
                      <template>
                        <el-input placeholder="请输入市场总价" v-model="baseInfoForm.market_sum_price"
                          oninput="value = (value.match(/\d+(.\d{0,2})?/) || [''])[0]"
                          @input="getPrice(1, 'change_market_price')"></el-input>
                      </template>
                    </el-table-column>
                    <el-table-column prop="shop_sum_price" label="销售总价"></el-table-column>
                    <el-table-column prop="enterprise_sum_price" label="分销总价"></el-table-column>
                    <el-table-column v-if="MixinIsFormEnterprise" prop="sum_price" label="会员总价"></el-table-column>
                    <el-table-column v-if="MixinIsFormEnterprise" prop="profit_price" label="利润率">
                      <template slot-scope="scope">{{ scope.row.shop_profit }}%</template>
                    </el-table-column>
                  </el-table>
                </el-form-item>

                <el-form-item label="礼包详情" class="goods-intro" style="width: 85%">
                  <UE ref="ue" :defaultMsg="baseInfoForm.intro"></UE>
                </el-form-item>
              </div>
            </div>
          </el-form>
        </div>
        <!--底部-->
        <div class="footer">
          <el-button-group>
            <!-- <el-button
            type="primary"
            size="small"
            @click="pre"
            >返回</el-button
          >-->
            <el-button :loading="createGoodsButtonLoading" type="primary" size="small" @click="aboveGoods">{{
              ($route.params && $route.params.id || comboId) ? "确认修改" : "确认创建"
            }}</el-button>
          </el-button-group>
        </div>
      </div>
      <en-goods-picker-fx :isSoldSeparatelyEnable="false" :show="showDialog" :showChooseBtn="false"
        :default-data="goodsData" @close="showDialog = false" @chooseAll="allChoose" />
      <en-goods-picker-fx :isSoldSeparatelyEnable="false" :comboId="comboId" :originGoodsId="changeRow.goods_id"
        :show="showChangeDialog" :showChooseBtn="false" :default-data="pageData.data" @close="showChangeDialog = false"
        @chooseAll="allChangeChoose" />
      <x-dialog :proxy="changeDialog">
        <div v-if="changeDialog" style="height:auto;">
          <en-table-layout :tableData="pageData.data" :loading="loading" @sort-change="handleSortChange"
            style="height:480px;overflow: hidden;" ref="tableWrap">
            <template slot="toolbar">
              <el-form-item>
                <el-button type="primary" size="small" @click="showChangeDialog = true;">选择商品</el-button>
              </el-form-item>
              <el-form-item>
                <el-input style="width: 300px" size="medium" placeholder="输入关键字" v-model="params.keyWord" clearable>
                  <el-select v-model="key_word" slot="prepend" placeholder="请选择" style="width: 140px">
                    <el-option label="商品名称" value="goods_name"></el-option>
                    <el-option label="商品编号" value="goods_sn"></el-option>
                  </el-select>
                </el-input>
              </el-form-item>
              <el-form-item>
                <el-button @click="changeFun(changeRow)" type="primary" size="small">搜索</el-button>
              </el-form-item>
            </template>
            <template slot="table-columns">
              <el-table-column label="商品图片" width="60" class-name="goods-cover-wrapper" fixed="left">
                <template slot-scope="{ row }">
                  <el-popover placement="right" trigger="hover">
                    <img :src="row.thumbnail" alt style="width: 300px" />
                    <img :src="row.thumbnail" class="goods-cover" alt slot="reference" />
                  </el-popover>
                </template>
              </el-table-column>
              <el-table-column label="前端展示图片" width="70" class-name="goods-cover-wrapper" fixed="left">
                <template slot-scope="{ row, $index }">
                  <div style="position: relative">
                    <div class="changBtn">
                      <el-upload class="upload-demo" :action="`${MixinUploadApi}?scene=goods`"
                        :on-success="thumbnailSuccess" :show-file-list="false">
                        <el-button @click="setIndex($index, 2)" style="padding: 0; color: #fff; font-size: 12px"
                          type="text">更换图片</el-button>
                      </el-upload>
                    </div>
                    <el-popover placement="right" trigger="hover">
                      <img :src="row.web_thumbnail ? row.web_thumbnail : row.thumbnail
                        " alt style="width: 300px" />
                      <img :src="row.web_thumbnail ? row.web_thumbnail : row.thumbnail
                        " class="goods-cover" alt slot="reference" />
                    </el-popover>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="商品名称" :show-overflow-tooltip="true" width="200" fixed>
                <template slot-scope="scope">
                  <span v-html="scope.row.goods_name"></span>
                </template>
              </el-table-column>
              <el-table-column label="前端名称" width="200">
                <template slot-scope="scope">
                  <el-tooltip class="item" effect="dark" :content="scope.row.goods_alias" placement="top">
                    <el-input v-model="scope.row.goods_alias"></el-input>
                  </el-tooltip>
                  <!-- <el-input v-model="scope.row.goods_alias"></el-input> -->
                </template>
              </el-table-column>
              <el-table-column label="商品编号" :show-overflow-tooltip="true" width="200">
                <template slot-scope="scope">
                  <span v-html="scope.row.sn"></span>
                </template>
              </el-table-column>

              <el-table-column v-if="MixinIsFormEnterprise" label="会员价" width="110" prop="price" sortable="custom"
                :key="'price'">
                <template slot-scope="scope">{{
                  scope.row.price | unitPrice("￥")
                }}</template>
              </el-table-column>

              <el-table-column label="市场价" width="110" prop="market" sortable="custom">
                <template slot-scope="scope">{{
                  scope.row.mktprice | unitPrice("￥")
                }}</template>
              </el-table-column>

              <el-table-column label="分销价" width="110" prop="enterprisePrice" sortable="custom">
                <template slot-scope="scope">{{
                  scope.row.enterprise_revise_price | unitPrice("￥")
                }}</template>
              </el-table-column>

              <!-- <el-table-column
                v-if="MixinIsFormEnterprise"
                label="毛利率"
                width="100"
                prop="profit"
                sortable="custom"
              >
                <template slot-scope="scope"
                  >{{
                    (scope.row.platform_profit &&
                      scope.row.platform_profit.toFixed(2)) ||
                    0
                  }}%</template
                >
              </el-table-column> -->

              <el-table-column label="销售价" width="110" prop="revisePrice" sortable="custom">
                <template slot-scope="scope">
                  <span>{{ scope.row.revise_price | unitPrice("￥") }}</span>
                </template>
              </el-table-column>

              <el-table-column v-if="MixinIsFormEnterprise" label="利润率" width="100" prop="shopProfit" sortable="custom">
                <template slot-scope="scope">{{ scope.row.shop_profit }}%</template>
              </el-table-column>

              <el-table-column label="商品数量" :key="'num'">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.num" oninput="value=value.replace(/[^0-9]/g,'')"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="160" fixed="right" :key="'opt'">
                <template slot-scope="scope">
                  <el-button size="mini" type="danger" :disabled="!scope.row.delete"
                    @click="handleDeleteCombo(scope.row)">删除</el-button>
                  <el-button v-if="scope.row.seq !== pageData.data[0].seq" type="warning" :disabled="scope.row.not_put"
                    icon="el-icon-top" circle size="mini"
                    @click="movement(scope.row, 'up', 'moveReplaceGoodsTh')"></el-button>
                  <el-button v-if="scope.row.seq !==
                    pageData.data[pageData.data.length - 1].seq
                    " type="warning" :disabled="scope.row.not_put" icon="el-icon-bottom" circle size="mini"
                    @click="movement(scope.row, 'down', 'moveReplaceGoodsTh')"></el-button>
                </template>
              </el-table-column>
            </template>
          </en-table-layout>
        </div>
      </x-dialog>
    </el-card>
  </div>
</template>

<script>
import * as API_Combo from "@/api/combo-card-shop/combo2";
import * as API_Login from "@/api/login";
import { RegExp } from "@/../ui-utils";
import Sortable from "sortablejs";
import EnTableLayout from "@/../ui-components/TableLayout/src/main";
import { UE } from "@/components";
import XDialog from "@/components/x-dialog/x-dialog";
import { $xDialog } from "@/components/x-dialog/dialog.proxy";
import * as API_account from "@/api/account";

export default {
  name: "comboEdit",
  components: {
    [UE.name]: UE,
    EnTableLayout,
    XDialog,
  },
  data () {
    const checkCombo = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("套餐价格不能为空"));
      } else if (value == 0) {
        callback(new Error("套餐价格必须大于零"));
      }
      if (!RegExp.money.test(value)) {
        callback(new Error("请输入数字值"));
      } else {
        // if (parseFloat(value) > parseFloat(this.baseInfoForm.shop_sum_price)) {
        //   callback(new Error("市场价格必须大于等于套餐"));
        // } else {
        callback();
        // }
      }
    };

    const checkGoodsGallery = (rule, value, callback) => {
      setTimeout(() => {
        if (!this.baseInfoForm.goods_gallery) {
          callback(new Error("请选择商品相册"));
        } else {
          callback();
        }
      }, 1000);
    };

    const checkGoodsData = (rule, value, callback) => {
      setTimeout(() => {
        if (!this.goodsData.length) {
          callback(new Error("请选择商品"));
        } else {
          callback();
        }
      }, 1000);
    };

    return {
      key_word: "goods_name",
      changeForm: {},
      changeDialog: $xDialog.create({
        title: "可替换商品",
        width: "960px",
        showClose: true,
        displayFooterExtras: true,
        beforeConfirm: this.editChange,
      }),
      thumbnailIndex: "",
      thumbnailIndex2: "",
      MixinIsFormEnterprise: false,
      /** 加载中。。。 */
      loading: false,
      /** 当前商品id*/
      comboId: null,
      /** 商品详情信息提交表单 */
      baseInfoForm: {
        /** 礼包名称 */
        gift_name: "",
        // 前端礼包名称
        goods_alias: "",
        // 所属公司
        company: "",
        /** 商品相册列表 */
        goods_gallery_list: [],
        /** 用来校验的商品相册 */
        goods_gallery: "",
        /** 套餐价格  */
        combo_price: 0,
        /** 销售总价格 */
        shop_sum_price: 0,
        /** 市场总价格 */
        market_sum_price: 0,
        /** 分销总价格 */
        enterprise_sum_price: 0,
        /** 会员总价 */
        sum_price: 0,
        /** 商品描述 详情 */
        intro: "",
        /** 利润率 */
        profit_price: 0,
        change_combo_price: 0,
        change_market_price: 0,
      },
      /** 临时存储商品相册信息 */
      goodsGalleryList: [],
      /** 商品图片放大地址 */
      dialogImageUrl: "",
      /** 是否显示放大图片弹框 */
      dialogImage: false,
      /** 商品详情的校验规则 */
      baseInfoFormRule: {
        web_gift_name: [
          { required: true, message: "请输入礼包名称", trigger: "blur" },
        ],
        gift_name: [
          { required: true, message: "请输入礼包名称", trigger: "blur" },
          {
            whitespace: true,
            message: "礼包名称不可为纯空格",
            trigger: "blur",
          },
          {
            min: 1,
            max: 32,
            message: "长度在 1 到 32 个字符",
            trigger: "blur",
          },
        ],
        goods_alias: [
          { required: true, message: "请输入礼包名称", trigger: "blur" },
          {
            whitespace: true,
            message: "礼包名称不可为纯空格",
            trigger: "blur",
          },
          {
            min: 1,
            max: 32,
            message: "长度在 1 到 32 个字符",
            trigger: "blur",
          },
        ],
        goods_data: [
          { required: true, validator: checkGoodsData, trigger: "blur" },
        ],
        combo_price: [
          { required: true, message: "请输入套餐价格", trigger: "blur" },
          { validator: checkCombo, trigger: "blur" },
        ],
        shop_sum_price: [
          { required: true, message: "请输入销售总价格", trigger: "blur" },
        ],
        market_sum_price: [
          { required: true, message: "请输入市场总价格", trigger: "blur" },
        ],
        enterprise_sum_price: [
          { required: true, message: "请输入分销总价格", trigger: "blur" },
        ],
        sum_price: [
          { required: true, message: "请输入会员总价格", trigger: "blur" },
        ],
        goods_gallery: [
          { required: true, validator: checkGoodsGallery, trigger: "change" },
        ],
        profit_price: [
          { required: true, message: "请输入利润率", trigger: "blur" },
        ],
      },
      createGoodsButtonLoading: false,
      goodsData: [],
      totalData: [],
      /** 选择器相关 */
      showDialog: false,
      showChangeDialog: false,
      chooseChangeList: [],
      firstPriceShowFlag: true,
      isPack: true,
      pageData: {
        data: [],
      },
      params: {
        keyWord: "",
        page_no: 1,
        page_size: 20,
      },
      changeRow: {},
      manual_exchange_goods: false,
    };
  },
  filters: {
    /** 销售状态格式化 */
    marketStatus (row) {
      switch (row.shop_goods_status) {
        case 0:
          return "已下架";
        case 1:
          return "已上架";
      }
    },
  },
  watch: {
    goodsData: function () {
      // 如果是编辑，价格的第一次回显使用接口返回的价格，而不是客户端自己计算的
      if (
        (this.$route.params &&
          this.$route.params.id &&
          this.firstPriceShowFlag) ||
        (this.comboId && this.firstPriceShowFlag)
      ) {
        this.firstPriceShowFlag = !this.firstPriceShowFlag;
      } else {
        this.getPrice();
      }
    },
  },
  beforeRouteUpdate (to, from, next) {
    if (this.$route.params && this.$route.params.id) {
      this.comboId = this.$route.params.id;
      this.GET_GoodData();
    }
    next();
  },
  created () {
    if (sessionStorage.getItem("MixinIsFormEnterprise")) {
      this.MixinIsFormEnterprise = JSON.parse(
        sessionStorage.getItem("MixinIsFormEnterprise")
      );
    }

    API_Login.getLoginType().then((res) => {
      if (res && res.parentLogin === 1) {
        this.MixinIsFormEnterprise = true;
        sessionStorage.setItem("MixinIsFormEnterprise", true);
      } else sessionStorage.setItem("MixinIsFormEnterprise", false);
      console.log(this.MixinIsFormEnterprise);
    });
    API_account.getShopmsg().then((res) => {
      this.manual_exchange_goods = res.manual_exchange_goods === 1;
      console.log(res, this.manual_exchange_goods, 111);
    });
    if (this.$route.params && this.$route.params.id) {
      this.comboId = this.$route.params.id;
      this.GET_GoodData();
    }
    this.totalData[0] = this.baseInfoForm;
    console.log(this.MixinIsFormEnterprise);
  },
  methods: {
    movement (data, move_type, req) {
      const params = {
        combo_id: this.comboId,
        goods_id: this.changeRow.id || data.goods_id,
        replace_goods_id: data.goods_id,
        move_type,
      };
      API_Combo[req](params).then((res) => {
        if (req === "moveComboGoodsTh") {
          this.GET_GoodData();
        } else {
          this.changeFun(this.changeRow);
        }

        console.log(res, this.goodsData);
      });
    },
    isnumber (e, key) {
      e = (e.match(/\d+(.\d{0,2})?/) || [""])[0];
      this.ruleForm[key] = e > 100000 ? 100000 : e;
      console.log(e, key);
    },
    setPrice (e) {
      this.baseInfoForm.market_sum_price = e;
      this.$forceUpdate();

      console.log(e);
    },
    handleDeleteCombo (row) {
      console.log(row);
      API_Combo.deleteReplaceGoodsTh(
        this.comboId,
        this.changeRow.id,
        row.id
      ).then((res) => {
        console.log(res);
        this.changeFun(this.changeRow);
        this.$message.success("删除成功");
      });
    },
    setIndex ($index, type) {
      type === 1
        ? (this.thumbnailIndex = $index)
        : (this.thumbnailIndex2 = $index);
      console.log(this.thumbnailIndex);
    },
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.GET_ComboList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_ComboList();
    },
    editChange () {
      // const goods_ids = this.pageData.data.map(item => {
      //   return item.goods_id;
      // });
      console.log(this.pageData.data);
      const goods_volist = this.pageData.data.map((item) => {
        return {
          exchange_goods_num: 0,
          goods_alias: item.goods_alias,
          goods_id: item.goods_id,
          goods_name: item.goods_name,
          num: item.num,
          shop_combo_id: this.comboId,
          web_big: item.web_big,
          web_original: item.web_original,
          web_small: item.web_small,
          web_thumbnail: item.web_thumbnail,
        };
      });
      console.log(goods_volist);
      API_Combo.addReplaceGoodsTh(this.comboId, this.changeRow.id, {
        goods_volist,
      }).then((res) => {
        console.log(res);
        this.$message.success("添加成功");
      });
    },
    changeFun (e) {
      this.changeRow = e;
      this.changeDialog.display();
      console.log(this.changeRow);
      let params = { ...this.params };
      params[this.key_word] = params.keyWord;
      delete params.keyWord;
      API_Combo.replaceGoodsListTh(this.changeRow.id, params).then((res) => {
        this.pageData.data = res.map((item) => {
          return { ...item, delete: 1 };
        });
        console.log(this.pageData);
      });
    },
    thumbnailSuccess (res) {
      console.log(this.thumbnailIndex);
      console.log(this.thumbnailIndex2);
      if (this.thumbnailIndex !== "") {
        this.goodsData[this.thumbnailIndex].web_thumbnail = res.url;
        this.goodsData[this.thumbnailIndex].web_big = res.url;
        this.goodsData[this.thumbnailIndex].web_small = res.url;
        this.goodsData[this.thumbnailIndex].web_original = res.url;
        this.goodsData = [...this.goodsData];
      } else {
        console.log(this.pageData.data);
        this.pageData.data[this.thumbnailIndex2].web_thumbnail = res.url;
        this.pageData.data[this.thumbnailIndex2].web_big = res.url;
        this.pageData.data[this.thumbnailIndex2].web_small = res.url;
        this.pageData.data[this.thumbnailIndex2].web_original = res.url;
      }
      console.log(res, this.goodsData, this.thumbnailIndex);
      console.log(this.goodsData[this.thumbnailIndex], res.url);
      this.thumbnailIndex = "";
      this.thumbnailIndex2 = "";
      this.getPrice();
      // this.totalData = [this.baseInfoForm];
    },
    getPrice (type = 0, key = "") {
      console.log("价格改了");
      this.baseInfoForm.change_combo_price = 1;
      if (key === "") {
        this.baseInfoForm.change_combo_price = 0;
        this.baseInfoForm.change_market_price = 0;
      } else {
        this.baseInfoForm[key] = type;
      }
      console.log(this.baseInfoForm);
      this.baseInfoForm = {
        ...this.baseInfoForm,
        ...this.goodsData.reduce(
          (p, c) => {
            p.combo_price =
              this.baseInfoForm.change_combo_price === 1
                ? this.baseInfoForm.combo_price
                : this.addPrice(
                  p.combo_price,
                  this.xPrice(c.revise_price, c.num)
                );
            p.shop_sum_price = this.addPrice(
              p.shop_sum_price,
              this.xPrice(c.revise_price, c.num)
            );
            p.market_sum_price =
              type === 1
                ? this.baseInfoForm.market_sum_price
                : this.addPrice(
                  p.market_sum_price,
                  this.xPrice(c.mktprice, c.num)
                );
            p.enterprise_sum_price = this.addPrice(
              p.enterprise_sum_price,
              this.xPrice(c.enterprise_revise_price, c.num)
            );
            p.sum_price = this.addPrice(
              p.sum_price,
              this.xPrice(c.price, c.num)
            );
            // 利润率计算
            p.profit_price = (
              ((p.shop_sum_price - p.sum_price) / p.shop_sum_price) *
              100
            ).toFixed(2);
            console.log(p.profit_price, 123, p.shop_sum_price, p.sum_price);
            return p;
          },
          {
            combo_price: 0,
            /** 销售总价格 */
            shop_sum_price: 0,

            /** 市场总价格 */
            market_sum_price: 0,

            /** 分销总价格 */
            enterprise_sum_price: 0,

            /** 会员总价 */
            sum_price: 0,
            /** 利润率 */
            profit_price: 0,
          }
        ),
      };
      this.totalData = [this.baseInfoForm];
      console.log(this.totalData[0]);
      this.$forceUpdate();
    },
    xPrice (a, num) {
      let ret = 0;
      for (let index = 0; index < num; index++) {
        ret = this.addPrice(ret, a);
      }
      return ret;
    },
    /**
     * 两位小数加法
     */
    addPrice (a, b) {
      a = a.toString();
      b = b.toString();
      a.split(".")[1]
        ? a.split(".")[1].length === 1 && (a += "0")
        : (a += "00");
      b.split(".")[1]
        ? b.split(".")[1].length === 1 && (b += "0")
        : (b += "00");
      return (Number(a.replace(".", "")) + Number(b.replace(".", ""))) / 100;
    },
    handleDelete (index, row) {
      this.goodsData.splice(index, 1);
    },
    /** 保存商品选择器选择的商品 */
    allChoose (list) {
      console.log(list, "list");
      const originGoodsId = this.goodsData.map((item) => {
        return item.goods_id;
      });
      list.forEach((i) => {
        if (originGoodsId.indexOf(i.goods_id) === -1) {
          this.$set(i, "num", 1);
          this.$set(i, "goods_alias", i.goods_name);
          this.$set(i, "not_put", true);
          this.goodsData.push(i);
        }
      });
      this.totalData[0] = this.baseInfoForm;
      console.log(this.goodsData);
    },
    allChangeChoose (list) {
      console.log(list, "list");
      const originGoodsId = this.pageData.data.map((item) => {
        return item.goods_id;
      });
      list.forEach((i) => {
        if (originGoodsId.indexOf(i.goods_id) === -1) {
          this.$set(i, "num", 1);
          this.$set(i, "goods_alias", i.goods_name);
          this.pageData.data.push(i);
        }
      });
      console.log(this.pageData.data, 111111);
      this.totalData[0] = this.baseInfoForm;
    },
    /** 返回*/
    pre () {
      this.loading = true;
    },

    /** 上架  */
    aboveGoods () {
      // const {market_sum_price,combo_price} = this.baseInfoForm;
      // if(combo_price <=0) {
      //   return this.$message.error("请输入正确的套餐价")
      // }
      // if(market_sum_price <=0) {
      //   return this.$message.error("请输入正确的市场总价")
      // }
      let _params = this.generateFormData(this.baseInfoForm);

      // return console.log(_params)
      // 检验礼包内商品的别名不能为空
      if (!this.goodsData.every((i) => i.goods_alias)) {
        this.$message.error("请补充完整礼包的前端名称");
        return;
      }

      this.createGoodsButtonLoading = true;
      // let _params = this.generateFormData(this.baseInfoForm);
      // return;
      this.$refs["baseInfoForm"].validate((valid) => {
        if (valid) {
          if (this.comboId) {
            /** 修改礼包 */
            API_Combo.editCombo(this.comboId, _params)
              .then(() => {
                this.$message.success("修改礼包成功");
                this.firstPriceShowFlag = true;
                this.GET_GoodData();
                // this.$store.dispatch("delCurrentViews", {
                //   view: this.$route,
                //   $router: this.$router
                // });
                // this.goodsData.forEach(i => {
                //   delete i.not_put
                // });
              })
              .finally(() => {
                this.createGoodsButtonLoading = false;
              });
          } else {
            /** 创建礼包 */
            API_Combo.addCombo(_params)
              .then((res) => {
                this.comboId = res.id;
                this.$message.success("创建礼包成功");
                this.firstPriceShowFlag = true;
                this.GET_GoodData();
                // this.$store.dispatch("delCurrentViews", {
                //   view: this.$route,
                //   $router: this.$router
                // });
                // this.goodsData.forEach(i => {
                //   this.$set(i, "not_put", false);
                // });
              })
              .finally(() => {
                this.createGoodsButtonLoading = false;
              });
          }
        } else {
          this.createGoodsButtonLoading = false;
          this.$message.error(
            "表单中存在未填写或者填写有误的地方,已有错误标示，请检查并正确填写"
          );
        }
      });
    },

    /** 搜索单个商品信息*/
    GET_GoodData () {
      console.log(this.comboId);
      API_Combo.getCombo(this.comboId).then((response) => {
        this.baseInfoForm = {
          ...response,
        };
        console.log("价格变动:", this.baseInfoForm);
        this.baseInfoForm["profit_price"] = (
          ((this.baseInfoForm["shop_sum_price"] -
            this.baseInfoForm["sum_price"]) /
            this.baseInfoForm["shop_sum_price"]) *
          100
        ).toFixed(2);
        /** 商品相册校验属性 */
        this.baseInfoForm.goods_gallery_list.forEach((key) => {
          this.$set(key, "url", key.original);
        });
        this.$nextTick(() => {
          this.setSort();
        });
        this.baseInfoForm.goods_gallery =
          this.baseInfoForm.goods_gallery_list.toString();
        this.goodsGalleryList = JSON.parse(
          JSON.stringify(this.baseInfoForm.goods_gallery_list)
        );
        this.goodsData = this.genGoodsData(response.goods_volist);
        this.totalData = [this.baseInfoForm];
        console.log(this.totalData[0]);
        this.$forceUpdate();
      });
    },

    /** 文件列表  图片个数 校验*/
    handleExceed (files, fileList) {
      this.$message.warning(`最多上传5张图`);
    },
    /** 文件列表移除文件时的钩子  图片删除校验*/
    handleRemove (file, fileList) {
      this.baseInfoForm.goods_gallery_list.forEach((key, index) => {
        if (key.img_id !== -1) {
          if (key.img_id === file.img_id) {
            this.baseInfoForm.goods_gallery_list.splice(index, 1);
          }
        } else {
          if (key.name === file.response.name) {
            this.baseInfoForm.goods_gallery_list.splice(index, 1);
          }
        }
      });
      if (fileList.length <= 0) {
        this.baseInfoForm.goods_gallery_list = [];
        this.baseInfoForm.goods_gallery =
          this.baseInfoForm.goods_gallery_list.toString();
      }
      this.$refs.baseInfoForm.validateField("goods_gallery");
    },

    /** 文件列表上传成功时的钩子  上传成功校验 */
    handleSuccess (response, file, fileList) {
      this.baseInfoForm.goods_gallery_list.push({
        img_id: -1,

        original: response.url,

        url: response.url,

        sort: 0,

        name: response.name,
      });
      this.baseInfoForm.goods_gallery =
        this.baseInfoForm.goods_gallery_list.toString();
      this.$refs["baseInfoForm"].validateField("goods_gallery");
      this.$nextTick(() => {
        this.setSort();
      });
    },

    /** 点击已上传的文件链接时的钩子 放大 */
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url;
      this.dialogImage = true;
    },

    /** 拖拽改变图片顺序 */
    setSort () {
      const el = document.querySelectorAll(
        "div.avatar-uploader > ul.el-upload-list--picture-card"
      )[0];
      this.sortable = Sortable.create(el, {
        ghostClass: "sortable-ghost",
        setData: function (dataTransfer) { },
        onEnd: (evt) => {
          let temp = this.baseInfoForm.goods_gallery_list[evt.oldIndex];
          this.baseInfoForm.goods_gallery_list[evt.oldIndex] =
            this.baseInfoForm.goods_gallery_list[evt.newIndex];
          this.baseInfoForm.goods_gallery_list[evt.newIndex] = temp;
        },
      });
    },
    handleSortChange ({ prop, order }) {
      if (order) {
        this.params.sort = `${prop}_${order.includes("asc") ? "asc" : "desc"}`;
      } else {
        this.params.sort = "";
      }
      this.changeFun(this.changeRow);
    },
    /** 图片上传之前的校验  */
    beforeAvatarUpload (file) {
      const isType =
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png";
      const isLt1M = file.size / 1024 / 1024 < 1;

      if (!isType) {
        this.$message.error("上传礼包图片只能是 JPG/JPEG/PNG 格式!");
      }
      if (!isLt1M) {
        this.$message.error("上传礼包图片大小不能超过 1MB!");
      }

      /** 重复校验*/
      const isExit = this.baseInfoForm.goods_gallery_list.some((key) => {
        return file.name === key.name;
      });
      if (isExit) {
        this.$message.error("图片已存在");
      }

      return isType && isLt1M && !isExit;
    },

    /** 构造表单数据 */
    generateFormData (data) {
      console.log(this.goodsData);
      let _params = {
        ...data,
        goods_volist: [
          ...this.goodsData.reduce((p, c) => {
            let {
              goods_alias,
              goods_id,
              goods_name,
              num,
              web_thumbnail,
              thumbnail,
              // id
            } = c;
            p = [
              ...p,
              {
                goods_alias,
                goods_id,
                goods_name,
                num,
                web_thumbnail: web_thumbnail || thumbnail,
                // id
              },
            ];
            return p;
          }, []),
        ],
      };
      delete _params.gallery_list;
      delete _params.goods_gallery;
      if (_params.goods_gallery_list) {
        _params.goods_gallery_list.forEach((key) => {
          delete key.name;
          delete key.big;
          delete key.goods_id;
          delete key.isdefault;
          delete key.small;
          delete key.status;
          delete key.thumbnail;
          delete key.tiny;
          delete key.uid;
          delete key.url;
        });
      }

      /** 处理UE的信息 */
      _params.intro = this.$refs["ue"].getUEContent();
      return _params;
    },
    /**
     * 编辑回显构造商品数据
     */
    genGoodsData (goods_volist) {
      let ret = [];
      goods_volist.forEach((item) => {
        let profit_price = 0;
        let {
          goods_id,
          goods_name,
          supplier_name,
          goods_alias,
          num,
          id,
          seq,
          exchange_goods_num,
          web_thumbnail,
          web_gift_name,
          shop_goods_vo: {
            enterprise_revise_price,
            goods_do: {
              thumbnail,
              ext_goods_id,
              goods_source,
              mktprice,
              price,
              enable_quantity,
              self_operated,
              sn,
              enterprise_profit
            },
            revise_price,
            shop_goods_status,
            disabled, // 值等于0时表示大平台下架商品
            shop_profit
          },
        } = item;
        ret.push({
          sn,
          id,
          goods_alias,
          web_gift_name,
          exchange_goods_num,
          web_thumbnail,
          goods_id,
          thumbnail,
          goods_name,
          supplier_name,
          ext_goods_id,
          goods_source,
          revise_price,
          mktprice,
          enterprise_revise_price,
          price,
          num,
          seq,
          enable_quantity,
          shop_goods_status,
          disabled,
          profit_price,
          self_operated,
          enterprise_profit,
          shop_profit
        });
      });
      console.log("价格变动:", this.baseInfoForm);
      console.log(ret);
      return ret;
    },
  },
};
</script>

<style lang="scss" scoped>
.changBtn {
  position: absolute;
  top: 20px;
  left: 6px;
  width: 80%;
  background-color: rgba(0, 0, 0, 0.2);
}

.content-goods-publish {
  padding: 15px;
  margin: 0 auto;
  text-align: center;
  background: none repeat 0 0 #fff;
  border-radius: 5px;

  /deep/.el-table {
    .el-table__fixed,
    .el-table__fixed-right {
      height: auto !important;
      bottom: 8px;
    }

    th {
      background: #e6ecf7;
      color: #333;
      font-weight: 600;
      line-height: 1;
      padding: 8px 0;
    }

    .cell {
      line-height: 1.2;
    }

    .el-table--border th {
      border-right: 1px solid rgba(220, 223, 230, 1);
    }

    .el-table--border td {
      border-right: 1px solid rgba(220, 223, 230, 1);
    }
  }
}

/*编辑基本信息*/
.el-form {
  padding-bottom: 80px;

  .el-form-item {
    // width: 100%;
    text-align: left;
  }
}

div.base-info-item {
  .el-form-item {
    width: 30%;
    min-width: 300px;
  }

  .goods-name-width {
    width: 50%;
    min-width: 300px;
  }
}

.goods-select ::v-deep .el-form-item__content {
  margin-left: 50px !important;
}

/*描述*/
.goods-intro {
  * {
    line-height: 1;
  }
}

/** 底部步骤 */
.footer {
  // width: calc(100% - 280px);
  padding: 10px;
  background-color: #fff;
  position: fixed;
  bottom: 0px;
  // left: 280px;
  text-align: center;
  z-index: 999;
  border-top: 1px solid #eee;
  left: 13.2%;
  right: 2%;
}

/*图片上传组件第一张图设置封面*/
.goods-images {
  /deep/ li.el-upload-list__item:first-child {
    position: relative;
  }

  /deep/ li.el-upload-list__item:first-child:after {
    content: "封";
    color: #fff;
    font-weight: bold;
    font-size: 12px;
    position: absolute;
    left: -15px;
    top: -6px;
    width: 40px;
    height: 24px;
    padding-top: 6px;
    background: #13ce66;
    text-align: center;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-box-shadow: 0 0 1pc 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 1pc 1px rgba(0, 0, 0, 0.2);
  }
}

.no-plus-icon ::v-deep {
  .el-upload--picture-card {
    display: none;
  }
}

::v-deep .el-input-group__append {
  padding: 0 10px;
  color: #333;
  background: #eaf0ff;
  border: 0;
}

::v-deep .price-input .el-input__inner {
  border: 0;
}

.price-input {
  width: 130px;
  border: 1px solid #dcdfe6;
  border-radius: 5px;
  overflow: hidden;
}

.page {
  .el-card:nth-child(1) {
    /deep/ .el-card__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 7px 20px;

      .button-back {
        height: 40px;

        .back_btn {
          width: 36px;
          height: 36px;
          background-color: #1a43a9;
          border-radius: 50%;

          img {
            width: 10px;
            height: 20px;
            position: relative;
            top: -2px;
            left: -4px;
          }
        }
      }
    }

    /deep/ .el-form-item__content {
      table {
        border: 1px solid #e6ecf7;

        tr>th {
          text-align: center;
          background-color: #e6ecf7;
        }

        tr>td {
          padding: 10px 5px;
          text-align: center;

          input {
            width: 100%;
          }

          .el-input__inner {
            padding: 0 5px;
          }
        }

        tr {

          .card-name,
          .card-section {
            min-width: 200px;
          }

          .icon-btn {
            border: 0;
            padding: 0;
            margin-left: 0;

            .el-icon-circle-plus-outline,
            .el-icon-remove-outline {
              font-size: 22px;
            }
          }
        }
      }
    }

    .card-info {
      /deep/ .el-form-item__label {
        display: inline-block !important;
        float: none !important;
      }

      /deep/ .el-form-item__content {
        margin-left: 55px !important;
      }
    }
  }

  .el-card {
    /deep/ .el-card__header {
      .title:before {
        content: " ";
        display: inline-block;
        position: relative;
        left: -20px;
        width: 5px;
        height: 20px;
        background: #1a43a9;
        transform: translate(0, 20%);
      }
    }

    margin-bottom: 20px;

    .card-body {
      .el-form-item {
        .el-form-item__content {
          .left {
            width: 200px;
          }

          .right {
            position: absolute;
            top: 0;
            left: 240px;
          }
        }
      }
    }
  }
}
</style>
